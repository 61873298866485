import React, { useState, useEffect } from "react";
import Layout from "../components/layout";
import Image from "../components/image";
import SEO from "../components/seo";
import Modal from "../components/modal";
import axios from "axios";
import {
  ModalLoading,
  ModalError,
  ModalSuccess,
} from "../components/modal-states";
import ReactGA from "react-ga";

const IndexPage = () => {
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [housingName, setHousingName] = useState("");

  const [emailHasError, setEmailHasError] = useState(false);
  const [nameHasError, setNameHasError] = useState(false);
  const [housingNameHasError, setHousingNameHasError] = useState(false);

  const [showModal, setShowModal] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [isLoading, setIsloading] = useState(false);
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    ReactGA.initialize("UA-162055246-1");
    ReactGA.pageview("/splash");
  }, []);

  const submitForm = () => {
    const formIsValid = validateForm();

    if (formIsValid) {
      ReactGA.event({
        category: "User",
        action: "Sent Interest",
      });
      setIsloading(true);
      setFormSubmitted(true);
      axios
        .post("https://mandrillapp.com/api/1.0/messages/send.json", {
          key: "MQeHItFY9Iqqd0OqM1UosA",
          message: {
            from_email: "splash@grannskap.se",
            from_name: "Intresseanmälan Grannskap",
            to: [
              {
                email: "support@grannskap.se",
                name: "Grannskap Support",
                type: "to",
              },
            ],
            headers: {
              "Reply-To": email,
            },
            autotext: "true",
            subject: "Intresseanmälan - Grannskap",
            html: `Namn: ${name}<br />E-post: ${email}<br />Brf: ${housingName}`,
          },
        })
        .then(function(response) {
          setIsloading(false);
        })
        .catch(function(error) {
          setIsloading(false);
          setHasError(true);
        });
    }
  };

  const validateForm = () => {
    setNameHasError(name === "" ? true : false);
    setEmailHasError(email === "" ? true : false);
    setHousingNameHasError(housingName === "" ? true : false);

    return name !== "" && email !== "" && housingName !== "";
  };

  const handleShowModal = () => {
    ReactGA.event({
      category: "User",
      action: "Open Interest Modal",
    });
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
    setFormSubmitted(false);
    setHasError(false);
    setEmail("");
    setName("");
    setHousingName("");
  };

  const handleGoToAccount = () => {
    ReactGA.event({
      category: "User",
      action: "Clicked Account Link",
    });
    window.location = "https://konto.grannskap.se/";
  };

  return (
    <Layout>
      <SEO
        title="Hemma på nätet"
        description="Grannskap® är en digital plattform för grannsämja och grannsamverkan."
        author="Grannskap AB"
      />
      <div className="relative bg-white overflow-hidden">
        <div className="max-w-screen-xl mx-auto ">
          <div className="relative z-10 pb-8 bg-white sm:pb-16 md:pb-20 lg:max-w-2xl lg:w-full lg:min-h-screen lg:pb-28 xl:pb-32  lg:flex lg:flex-col lg:justify-center">
            <svg
              className="hidden lg:block absolute right-0 inset-y-0 h-full w-48 text-white transform translate-x-1/2"
              fill="currentColor"
              viewBox="0 0 100 100"
              preserveAspectRatio="none"
            >
              <polygon points="50,0 100,0 50,100 0,100" />
            </svg>

            <div className="relative pt-6 px-4 sm:px-6 lg:px-8">
              <nav className="relative flex items-center justify-between sm:justify-center lg:justify-between sm:h-10 lg:justify-start">
                <div className="flex items-center flex-grow flex-shrink-0 lg:flex-grow-0 sm:mt-4 md:mt-0">
                  <div className="flex items-center justify-between sm:justify-center lg:justify-between w-full lg:w-auto">
                    <svg
                      className="w-16 h-16 sm:mx-auto"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 330.6 272.72"
                    >
                      <path
                        d="M458.16,253.22h0l-89.52-67.44h0a17.88,17.88,0,0,0-21.52,0h0l-72.05,54.28L216.94,198h0A11,11,0,0,0,204,198h0l-64.75,46.84h0a11,11,0,0,0-4.57,8.95v82.65a11,11,0,0,0,11,11h28.94a4.18,4.18,0,0,1,3.11,1.39h0l29.6,33v0a4.17,4.17,0,0,0,7.28-2.77V351.65a4.17,4.17,0,0,1,4.17-4.17h56.41a11,11,0,0,0,11-11V260.29l9.16,6.62h0a11,11,0,0,1,4.58,8.94v82.65a11,11,0,0,1-11,11h-38.5v9.56A17.9,17.9,0,0,0,268.36,397h75.79a6.86,6.86,0,0,1,6.86,6.86V448a6.87,6.87,0,0,0,12,4.57h0l47.64-53.29h0a6.85,6.85,0,0,1,5.12-2.29H447.4a17.9,17.9,0,0,0,17.9-17.91V267.52A17.84,17.84,0,0,0,458.16,253.22Z"
                        transform="translate(-134.7 -182.17)"
                        fill="#09796e"
                      />
                    </svg>
                  </div>
                </div>
              </nav>
            </div>

            <div className="mt-10 mx-auto lg:mx-0 max-w-screen-xl px-4 sm:mt-12 sm:px-6 md:mt-16 lg:mt-20 lg:px-8 xl:mt-28">
              <div className="sm:text-center lg:text-left">
                <h2 className="text-4xl tracking-tight leading-10 font-extrabold text-primary sm:text-5xl sm:leading-none md:text-6xl">
                  Grannskap<span className="text-xl">®</span>
                </h2>
                <p className="mt-3 text-base text-gray-800 font-bold sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-xl lg:mx-0">
                  Grannskap® är en digital plattform för grannsämja och
                  grannsamverkan. Vår vision är bostadsområden där digital
                  närvaro och digital samverkan lever i harmoni med den
                  traditionella kontakten och gemenskapen grannar emellan.
                </p>
                <p className="mt-2 text-base text-gray-600 font-medium sm:mt-3 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-3 md:text-xl lg:mx-0">
                  Snart lanserar vi vår nya webbapplikation för
                  bostadsrättsföreningar - en komplett digital lösning för både
                  styrelsearbete och boende.
                </p>
                <p className="mt-2 text-base text-gray-600 font-medium sm:mt-3 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-3 md:text-xl lg:mx-0">
                  Är du och dina grannar hemma på nätet? Välkommen till ditt
                  Grannskap®!
                </p>
                <div className="mt-5 sm:mt-10 sm:flex sm:justify-center lg:justify-start">
                  <span className="inline-flex rounded-md shadow">
                    <button
                      type="button"
                      className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-primary hover:bg-primary-light focus:outline-none focus:shadow-outline transition duration-150 ease-in-out md:py-4 md:text-lg md:px-10"
                      onClick={() => handleShowModal()}
                    >
                      Anmäl intresse
                    </button>
                  </span>
                  <span className="inline-flex rounded-md shadow">
                    <button
                      type="button"
                      className="w-full flex items-center justify-center px-8 py-3 ml-2 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-secondary hover:bg-secondary-light focus:outline-none focus:shadow-outline transition duration-150 ease-in-out md:py-4 md:text-lg md:px-10"
                      onClick={() => handleGoToAccount()}
                    >
                      Mina sidor
                    </button>
                  </span>
                </div>
                <p className="mt-3 text-base text-gray-500 sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-xl lg:mx-0">
                  Befintlig kund som behöver support? support@grannskap.se
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
          <Image />
        </div>
      </div>
      <Modal open={showModal}>
        <>
          {formSubmitted ? (
            <>
              {hasError && <ModalError closeModal={closeModal} />}
              {isLoading && <ModalLoading closeModal={closeModal} />}
              {!isLoading && !hasError && (
                <ModalSuccess closeModal={closeModal} />
              )}
            </>
          ) : (
            <>
              <div className="mt-3 text-center sm:mt-5">
                <h3 className="text-lg leading-6 font-medium text-gray-900">
                  Häng med!
                </h3>
                <div className="mt-4 mb-8">
                  <p className="mb-2 text-sm leading-5 text-gray-700">
                    Snart lanserar vi vår helt nya plattform för
                    bostadsrättsföreningar. Fyll i dina uppgifter här nedan så
                    hör vi av oss när det är dags för beta!
                  </p>
                </div>
              </div>

              <div>
                <div>
                  <div>
                    <label
                      htmlFor="email"
                      className="block text-sm font-medium leading-5 text-gray-700"
                    >
                      E-post *
                    </label>
                    <div className="mt-1 relative rounded-md shadow-sm">
                      <input
                        type="email"
                        value={email}
                        onChange={e => setEmail(e.target.value)}
                        name="email"
                        className={
                          emailHasError
                            ? "appearance-none block w-full px-3 py-2 border border-red-300 rounded-md placeholder-red-300 focus:outline-none focus:shadow-outline-red focus:border-red-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                            : "appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                        }
                        id="email"
                      />
                      {emailHasError && (
                        <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                          <svg
                            className="h-5 w-5 text-red-500"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                          >
                            <path
                              fillRule="evenodd"
                              d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z"
                              clipRule="evenodd"
                            />
                          </svg>
                        </div>
                      )}
                    </div>
                    {emailHasError && (
                      <p className="mt-2 text-sm text-red-600">
                        Obligatoriskt fält.
                      </p>
                    )}
                  </div>
                  <div className="mt-2">
                    <label
                      htmlFor="name"
                      className="block text-sm font-medium leading-5 text-gray-700"
                    >
                      Ditt namn *
                    </label>
                    <div className="mt-1 relative rounded-md shadow-sm">
                      <input
                        type="text"
                        value={name}
                        onChange={e => setName(e.target.value)}
                        name="name"
                        className={
                          nameHasError
                            ? "appearance-none block w-full px-3 py-2 border border-red-300 rounded-md placeholder-red-300 focus:outline-none focus:shadow-outline-red focus:border-red-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                            : "appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                        }
                        id="name"
                      />
                      {nameHasError && (
                        <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                          <svg
                            className="h-5 w-5 text-red-500"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                          >
                            <path
                              fillRule="evenodd"
                              d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z"
                              clipRule="evenodd"
                            />
                          </svg>
                        </div>
                      )}
                    </div>
                    {nameHasError && (
                      <p className="mt-2 text-sm text-red-600">
                        Obligatoriskt fält.
                      </p>
                    )}
                  </div>
                  <div className="mt-2">
                    <label
                      htmlFor="housing-name"
                      className="block text-sm font-medium leading-5 text-gray-700"
                    >
                      Föreningens namn *
                    </label>
                    <div className="mt-1 relative rounded-md shadow-sm">
                      <input
                        type="text"
                        value={housingName}
                        onChange={e => setHousingName(e.target.value)}
                        name="housing-name"
                        className={
                          housingNameHasError
                            ? "appearance-none block w-full px-3 py-2 border border-red-300 rounded-md placeholder-red-300 focus:outline-none focus:shadow-outline-red focus:border-red-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                            : "appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                        }
                        id="housing-name"
                      />
                      {housingNameHasError && (
                        <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                          <svg
                            className="h-5 w-5 text-red-500"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                          >
                            <path
                              fillRule="evenodd"
                              d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z"
                              clipRule="evenodd"
                            />
                          </svg>
                        </div>
                      )}
                    </div>
                    {housingNameHasError && (
                      <p className="mt-2 text-sm text-red-600">
                        Obligatoriskt fält.
                      </p>
                    )}
                  </div>
                  <div className="mt-5 sm:mt-6">
                    <span className="flex w-full rounded-md shadow-sm">
                      <input
                        type="submit"
                        value="Skicka"
                        name="subscribe"
                        id="mc-embedded-subscribe"
                        className="inline-flex justify-center w-full rounded-md border border-transparent px-4 py-2 bg-primary text-base leading-6 font-medium text-white shadow-sm cursor-pointer hover:bg-primary-light focus:outline-none focus:bg-primary-light focus:shadow-outline-primary-light transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                        onClick={submitForm}
                        readOnly
                      />
                    </span>
                  </div>
                </div>
              </div>
              <div className="mt-3">
                <span className="flex w-full rounded-md shadow-sm">
                  <button
                    type="button"
                    className="inline-flex justify-center w-full rounded-md border border-transparent px-4 py-2 bg-secondary text-base leading-6 font-medium text-white shadow-sm hover:bg-secondary-light focus:outline-none focus:bg-secondary-light focus:shadow-outline-secondary-light transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                    onClick={closeModal}
                  >
                    Stäng
                  </button>
                </span>
              </div>
            </>
          )}
        </>
      </Modal>
    </Layout>
  );
};

export default IndexPage;
